import React, { useState } from "react"

import { graphql, useStaticQuery } from "gatsby"

import ArrowIcon from "../assets/images/arrow.svg"
import LogoImage from "../assets/images/iMatthewsFull.svg"
import Link from "../utils/link"

import "./footer.scss"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Footer = () => {
  const [newsLetter, setNewsLetter] = useState("")
  const res = useStaticQuery(footerData)
  const navigationFields =
    res.wpPost.acfHeader.headerFooterFlyoutData[0].navigationFields
  const SiteInfo = res.wp.siteSettings.settings
  const PolicyInfo = res.wpPost.acfHeader.headerFooterFlyoutData[1]
  const NewsLetter = res.wpPost.acfHeader.headerFooterFlyoutData[3]
  const Copyright = res.wpPost.acfHeader.headerFooterFlyoutData[2]

  const [result, setResult] = useState(null)

  const formRef = React.createRef()
  const handleSubmit = (e) => {
    e.preventDefault()

    if (!formRef.current.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": formRef.current.getAttribute("name"),
        email: newsLetter,
      }),
    })
      .then(() => setResult("success"))
      .catch((error) => setResult("fail"))
  }

  let props = {
    name: "newsletter",
    ref: formRef,
    onSubmit: handleSubmit,
    "data-netlify": "true",
    "data-netlify-honeypot": "bot-field",
  }

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__container__left">
          <div className="footer__container__left__logo">
            <Link to="/">
              <img src={LogoImage} alt="Matthews" />
            </Link>
          </div>
          <div className="footer__container__left__details">
            <div
              className="footer__container__left__details__address"
              dangerouslySetInnerHTML={{ __html: SiteInfo.address }}
            ></div>
            <div div className="footer__container__left__details__numbers">
              <Link to={`tel:${SiteInfo.phone}`}>
                <p>P {SiteInfo.phone}</p>
              </Link>
              <Link to={`mailto:${SiteInfo.email}`}>
                <p>{SiteInfo.email}</p>
              </Link>
            </div>
            <div div className="footer__container__left__details__social">
              <Link to={SiteInfo.instagram}>
                <p>Instagram</p>
              </Link>
              <Link to={SiteInfo.linkedin}>
                <p>LinkedIn</p>
              </Link>
              <Link to={SiteInfo.facebook}>
                <p>Facebook</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="footer__container__right">
          <div className="footer__container__right__link-newsletter">
            <div className="footer__container__right__link-newsletter__links">
              <ul>
                {navigationFields.map((data, index) => (
                  <li key={index}>
                    <Link to={data.item.url}>{data.item.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="footer__container__right__link-newsletter__newsletter">
              {result === "success" ? (
                <p>Thanks for subscribing</p>
              ) : (
                <form {...props}>
                  <label htmlFor="newsletter">
                    <input
                      type="text"
                      required
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      title="Please enter valid email"
                      placeholder={NewsLetter.placeholder}
                      name="email"
                      onChange={(e) => {
                        setNewsLetter(e.target.value.toLowerCase())
                      }}
                      value={newsLetter}
                    />
                  </label>
                  <div className="icon">
                    <label htmlFor="subscribe">
                      <button type="submit">
                        <img src={ArrowIcon} alt="arrow" />
                      </button>
                    </label>
                  </div>
                </form>
              )}
            </div>
          </div>
          <div className="footer__container__right__terms">
            <div className="footer__container__right__terms__privacy-policy">
              <Link to={PolicyInfo.termsAndConditions.url}>
                <p>{PolicyInfo.termsAndConditions.title}</p>
              </Link>
              <Link to={PolicyInfo.privacyPolicy.url}>
                <p>{PolicyInfo.privacyPolicy.title}</p>
              </Link>
            </div>
            <div className="footer__container__right__terms__copyrights">
              <p>{Copyright.site}</p>
              <p>
                {Copyright.designInfo}{" "}
                <span>
                  <Link to={Copyright.lbd.url}>{Copyright.lbd.title} </Link>
                </span>
                +
                <span>
                  <Link to={Copyright.atollon.url}>
                    {" "}
                    {Copyright.atollon.title}{" "}
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__container-mobile">
        <Link to="/">
          <img src={LogoImage} alt="matthews" />
        </Link>
        <div className="footer__container-mobile__newsletter">
          <form name="newsletter">
            <label htmlFor="newsletter">
              <input
                type="text"
                required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                title="Please enter valid email"
                placeholder={NewsLetter.placeholder}
                onChange={(e) => {
                  setNewsLetter(e.target.value.toLowerCase())
                }}
                value={newsLetter}
              />
            </label>
            <div className="icon">
              <label htmlFor="subscribe">
                <button type="submit">
                  <img src={ArrowIcon} alt="arrow" />
                </button>
              </label>
            </div>
          </form>
        </div>
        <div className="footer__container-mobile__details">
          <div className="footer__container-mobile__details__left">
            <div className="footer__container-mobile__details__left__nav">
              {navigationFields.map((data, index) => (
                <p key={index}>
                  <Link to={data.item.url}>{data.item.title}</Link>
                </p>
              ))}
            </div>
            <div className="footer__container-mobile__details__left__terms">
              <Link to={PolicyInfo.termsAndConditions.url}>
                <p>{PolicyInfo.termsAndConditions.title}</p>
              </Link>
              <Link to={PolicyInfo.privacyPolicy.url}>
                <p>{PolicyInfo.privacyPolicy.title}</p>
              </Link>
            </div>
          </div>
          <div className="footer__container-mobile__details__right">
            <div
              className="footer__container-mobile__details__right__address"
              dangerouslySetInnerHTML={{ __html: SiteInfo.address }}
            ></div>
            <div className="footer__container-mobile__details__right__number">
              <Link to={`tel:${SiteInfo.phone}`}>
                <p>P {SiteInfo.phone}</p>
              </Link>
              <Link to={`mailto:${SiteInfo.email}`}>
                <p>{SiteInfo.email}</p>
              </Link>
            </div>
            <div className="footer__container-mobile__details__right__social">
              <Link to={SiteInfo.instagram}>
                <p>Instagram</p>
              </Link>
              <Link to={SiteInfo.linkedin}>
                <p>LinkedIn</p>
              </Link>
              <Link to={SiteInfo.facebook}>
                <p>Facebook</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="footer__container-mobile__copyright">
          <p>{Copyright.site}</p>
          <p>
            {Copyright.designInfo}{" "}
            <span>
              <Link to={Copyright.lbd.url}>{Copyright.lbd.title} </Link>
            </span>
            +
            <span>
              <Link to={Copyright.atollon.url}>
                {" "}
                {Copyright.atollon.title}{" "}
              </Link>
            </span>
          </p>
        </div>
      </div>
    </footer>
  )
}

const footerData = graphql`
  query {
    wpPost(slug: { eq: "header-footer-flyout" }) {
      acfHeader {
        fieldGroupName
        headerFooterFlyoutData {
          ... on WpPost_Acfheader_HeaderFooterFlyoutData_NavigationData {
            fieldGroupName
            navigationFields {
              item {
                title
                url
              }
            }
          }
          ... on WpPost_Acfheader_HeaderFooterFlyoutData_PolicyInformation {
            fieldGroupName
            privacyPolicy {
              title
              url
            }
            termsAndConditions {
              title
              url
            }
          }
          ... on WpPost_Acfheader_HeaderFooterFlyoutData_CopyrightInfo {
            fieldGroupName
            atollon {
              title
              url
            }
            site
            lbd {
              title
              url
            }
            designInfo
          }
          ... on WpPost_Acfheader_HeaderFooterFlyoutData_Newsletter {
            fieldGroupName
            placeholder
          }
        }
      }
    }
    wp {
      siteSettings {
        settings {
          address
          email
          facebook
          fieldGroupName
          instagram
          linkedin
          phone
          twitter
        }
      }
    }
  }
`

export default Footer
