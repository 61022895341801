import React from "react"
import { Script } from "gatsby"

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <Script type="text/javascript" src="https://birdeye.com/embed/v6/139595/1/349455744/d202895050e5a65c3533e64aaa12af128e1f6244e577e508" />
    </>
  )
}
