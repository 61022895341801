import React, { Component } from "react"

function setCookie(name, value, days) {
  let expires = ""
  if (days) {
    let date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = "; expires=" + date.toUTCString()
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/"
}
function getCookie(name) {
  let nameEQ = name + "="
  let ca = document.cookie.split(";")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === " ") c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return false
}

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Form extends Component {
  state = {
    result: "",
    msg: "",
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => {
        this.setState({ result: "success" })
      })
      .catch((error) => this.setState({ result: "fail", msg: error }))
  }

  render() {
    let props = {
      ref: "form",
      name: "popup_newsletter",
      className: "form",
      onSubmit: this.handleSubmit,
      "data-netlify": "true",
      "data-netlify-honeypot": "bot-field",
    }

    return (
      <form {...props}>
        <div className="form__row form__row--trio">
          <input
            type="text"
            name="first_name"
            placeholder="First Name"
            onChange={this.handleChange}
            required
          />
          <input
            type="text"
            name="last_name"
            placeholder="Last Name"
            onChange={this.handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            onChange={this.handleChange}
            required
          />
        </div>
        <div className="form__row">
          <button type="submit" className="btn">
            Subscribe
          </button>
        </div>
      </form>
    )
  }
}

class NewsletterPopup extends Component {
  state = {
    show: false,
    result: "",
    msg: "",
  }

  hidePopup = () => {
    this.setState({ show: false })
  }

  componentDidMount() {
    if (getCookie("newsletter-popup")) return

    setTimeout(() => {
      this.setState({ show: true }, () => {
        setCookie("newsletter-popup", "done", 365)
      })
    }, 4000)
  }

  render() {
    let { show } = this.state
    return (
      <div
        className={
          show ? "popup popup--newsletter active" : "popup popup--newsletter"
        }
      >
        <div className="popup__wrapper">
          <button
            type="button"
            className="popup__close"
            onClick={this.hidePopup}
          >
            Close
          </button>
          <div className="popup__body">
            <h4 className="popup__title">Newsletter Subscribe</h4>
            <p className="popup__subtitle">Keep up to date</p>
          </div>
          <div className="popup__form">
            <Form />
          </div>
        </div>
      </div>
    )
  }
}

export default NewsletterPopup
