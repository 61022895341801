exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buy-js": () => import("./../../../src/pages/buy.js" /* webpackChunkName: "component---src-pages-buy-js" */),
  "component---src-pages-lease-js": () => import("./../../../src/pages/lease.js" /* webpackChunkName: "component---src-pages-lease-js" */),
  "component---src-pages-sell-js": () => import("./../../../src/pages/sell.js" /* webpackChunkName: "component---src-pages-sell-js" */),
  "component---src-templates-agent-details-js": () => import("./../../../src/templates/AgentDetails.js" /* webpackChunkName: "component---src-templates-agent-details-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-property-details-js": () => import("./../../../src/templates/PropertyDetails.js" /* webpackChunkName: "component---src-templates-property-details-js" */)
}

