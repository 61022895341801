import React, { useState, useEffect } from "react"
import { Script } from "gatsby"


// import { graphql, useStaticQuery } from "gatsby"

import PropTypes from "prop-types"

import Footer from "../components/footer"
import Header from "../components/header"
import NewsletterPopup from "../components/newsletter-popup"

import "../assets/scss/main.scss"

const Layout = ({ children, location }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  const [isDark, setIsDark] = useState(false)
  const [isTransparent, setIsTransparent] = useState(false)

  useEffect(() => {
    if (location.pathname.includes("agent")) {
      setIsDark(true)
    } else {
      setIsDark(false)
    }

    if (children?.props?.data?.wpPage?.acf?.contentBlocks[0]?.fieldGroupName === 'page_Acf_ContentBlocks_FullWidthVideo') {
      setIsTransparent(true)
    } else {
      setIsTransparent(false)
    }

  }, [location.pathname])

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)")
    handleDarkmode(darkModeMediaQuery)
    function handleDarkmode(e) {
      const darkModeOn = e.matches // true if dark mode is enabled
      const largeFavicons = document.querySelectorAll('link[rel="icon"]') // get favicon.ico element
      if (!largeFavicons) {
        return // where are our favicon elements???
      }
      // replace icons with dark/light themes as appropriate
      if (darkModeOn) {
        largeFavicons.forEach((ele) => {
          ele.href = require("../assets/images/favicon_light.svg").default
        })
      } else {
        largeFavicons.forEach((ele) => {
          ele.href = require("../assets/images/favicon_dark.svg").default
        })
      }
    }
    darkModeMediaQuery.addListener(handleDarkmode)
  }, [])

  return (
    <>
      <main>
        <Header isDark={isDark} isTransparent={isTransparent} />
        {children}
        <Footer />
        <NewsletterPopup />
      </main>
      <div id="bf-revz-widget-349455744"></div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.node.isRequired,
}

export default Layout
