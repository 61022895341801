import React, { useState } from "react"

import { graphql, useStaticQuery } from "gatsby"

import PropTypes from "prop-types"

import ArrowIcon from "../assets/images/arrow.svg"
import CloseIcon from "../assets/images/close.svg"
import HamburgerIcon from "../assets/images/hamburger.svg"
import LogoImage from "../assets/images/iMatthewsFull.svg"
import Link from "../utils/link"

import "./header.scss"

const Header = ({ isDark, isTransparent }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [newsLetter, setNewsLetter] = useState("")
  const [isTrans, setIsTrans] = useState(isTransparent || false)

  const res = useStaticQuery(headerData)
  const navigationFields =
    res.wpPost.acfHeader.headerFooterFlyoutData[0].navigationFields
  const SiteInfo = res.wp.siteSettings.settings
  const PolicyInfo = res.wpPost.acfHeader.headerFooterFlyoutData[1]
  const NewsLetter = res.wpPost.acfHeader.headerFooterFlyoutData[3]
  const Copyright = res.wpPost.acfHeader.headerFooterFlyoutData[2]

  React.useEffect(() => {
    // if scroll is less than 100px, remove the trans-nav class
    const onScroll = () => {
      if (window.scrollY >= 100 || !isTransparent) {
        setIsTrans(false)
      } else {
        setIsTrans(true)
      }
    }

    onScroll()
    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [isTransparent])

  const toggleIsVisible = () => {
    setIsVisible(!isVisible)
    const body = document.querySelector("body")
    body.classList.toggle("scroll-prevent")
  }

  return (
    <header className={[isDark ? "dark-nav" : '', isTrans ? 'trans-nav' : ''].join(' ')}>
      <div className="header__container">
        <Link to="/" title="Matthews" className="header__container__logo">
          <img src={LogoImage} alt="Matthews Logo" />
        </Link>
        <ul className="header__container__link">
          {navigationFields.map((data, index) => (
            <li key={index}>
              <Link to={data.item.url}>{data.item.title}</Link>
            </li>
          ))}
        </ul>
        <button
          className="header__container__hamburger"
          onClick={toggleIsVisible}
        >
          <img src={HamburgerIcon} alt="hamburger" />
        </button>
      </div>
      <div className={isVisible ? "header__flyout active" : "header__flyout"}>
        <div className="header__flyout__nav">
          <Link
            to="/"
            className="header__flyout__nav__logo"
            onClick={toggleIsVisible}
          >
            <img src={LogoImage} alt="Matthews" />
          </Link>
          <button
            className="header__flyout__nav__close"
            onClick={toggleIsVisible}
          >
            <img src={CloseIcon} alt="close" />
          </button>
        </div>
        <ul className="header__flyout__link">
          {navigationFields.map((data, index) => (
            <li key={index}>
              <Link to={data.item.url} onClick={toggleIsVisible}>
                {data.item.title}
              </Link>
            </li>
          ))}
        </ul>
        <div className="header__flyout__contact">
          <div className="header__flyout__contact__top">
            <div
              className="header__flyout__contact__top__address"
              dangerouslySetInnerHTML={{ __html: SiteInfo.address }}
            ></div>

            <div>
              <Link to={`tel:${SiteInfo.phone}`}>
                <p>P {SiteInfo.phone}</p>
              </Link>
              <Link to={`tel:${SiteInfo.fax}`}>
                <p>F {SiteInfo.fax}</p>
              </Link>
              <Link to={`mailto:${SiteInfo.email}`}>
                <p>{SiteInfo.email}</p>
              </Link>
            </div>
          </div>
          <div className="header__flyout__contact__bottom">
            <div>
              <Link to={PolicyInfo.termsAndConditions.url}>
                <p>{PolicyInfo.termsAndConditions.title}</p>
              </Link>
              <Link to={PolicyInfo.privacyPolicy.url}>
                <p>{PolicyInfo.privacyPolicy.title}</p>
              </Link>
            </div>
            <div>
              <Link to={SiteInfo.instagram}>
                <p>Instagram</p>
              </Link>
              <Link to={SiteInfo.linkedin}>
                <p>LinkedIn</p>
              </Link>
              <Link to={SiteInfo.twitter}>
                <p>Twitter</p>
              </Link>
            </div>
          </div>
          <form name="newsletter">
            <label htmlFor="newsletter">
              <input
                type="text"
                required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                title="Please enter valid email"
                placeholder={NewsLetter.placeholder}
                onChange={(e) => {
                  setNewsLetter(e.target.value.toLowerCase())
                }}
                value={newsLetter}
              />
            </label>
            <div className="icon">
              <label htmlFor="subscribe">
                <button type="submit">
                  <img src={ArrowIcon} alt="arrow" />
                </button>
              </label>
            </div>
          </form>

          <div className="header__flyout__contact__copyrights">
            <p>{Copyright.site}</p>
            <p>
              {Copyright.designInfo}{" "}
              <span>
                <Link to={Copyright.lbd.url}>{Copyright.lbd.title} </Link>
              </span>
              +
              <span>
                <Link to={Copyright.atollon.url}>
                  {" "}
                  {Copyright.atollon.title}{" "}
                </Link>
              </span>
            </p>
          </div>
        </div>
      </div>
    </header>
  )
}
const headerData = graphql`
  query {
    wpPost(slug: { eq: "header-footer-flyout" }) {
      acfHeader {
        fieldGroupName
        headerFooterFlyoutData {
          ... on WpPost_Acfheader_HeaderFooterFlyoutData_NavigationData {
            fieldGroupName
            navigationFields {
              item {
                title
                url
              }
            }
          }
          ... on WpPost_Acfheader_HeaderFooterFlyoutData_PolicyInformation {
            fieldGroupName
            privacyPolicy {
              title
              url
            }
            termsAndConditions {
              title
              url
            }
          }
          ... on WpPost_Acfheader_HeaderFooterFlyoutData_CopyrightInfo {
            fieldGroupName
            atollon {
              title
              url
            }
            site
            lbd {
              title
              url
            }
            designInfo
          }
          ... on WpPost_Acfheader_HeaderFooterFlyoutData_Newsletter {
            fieldGroupName
            placeholder
          }
        }
      }
    }
    wp {
      siteSettings {
        settings {
          address
          email
          facebook
          fax
          fieldGroupName
          instagram
          linkedin
          phone
          twitter
        }
      }
    }
  }
`

Header.propTypes = {
  isDark: PropTypes.bool,
}

export default Header
